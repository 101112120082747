export default {
  available_vehicles: 'Pieejami modeļi',
  currency: 'EUR',
  currency_monthly: '',
  popup_close: '',
  navigate_back: 'Atpakaļ',
  vehicle_list_no_matches: '',
  vehicle_list_other_vehicles: '',
  vehicle_list_sort_by: '',
  vehicle_list_sort_by_price: '',
  filter_models: 'MODEĻI',
  filter_location: '',
  filter_location_title: '',
  filter_location_search_radius: '',
  filter_location_search_distance: '',
  filter_reset: 'Notīriet Filtru',
  filter_price: 'CENA',
  filter_color: 'KRĀSA',
  filter_availability: 'PIEEJAMĪBA',
  filter_power: 'JAUDA',
  filter_torque: 'GRIEZES MOMENTS',
  filter_applied_models: '',
  filter_applied_locations: '',
  filter_search_for_equipment: 'Meklēt aprīkojumu',
  vehicle_details_engine_output: '',
  vehicle_details_cylinder_capacity: '',
  vehicle_details_vehicle_identifier: '',
  vehicle_details_360_View: '',
  vehicle_details_overview: '',
  vehicle_details_gallery: 'GALERIJA',
  vehicle_details_video: '',
  vehicle_details_equipment: 'Aprīkojums',
  vehicle_details_accessories: 'Aprīkojums',
  vehicle_details_total_price: 'CENA',
  vehicle_details_financing_from: 'Financing from',
  vehicle_details_open_calculator: 'Calculator',
  vehicle_details_request_offer: '',
  vehicle_details_real_images: 'Vehicle details',
  financial_calc_downpayment: 'Downpayment',
  financial_calc_period: 'Duration',
  financial_calc_remaining: 'Residual value',
  financial_calc_monthly_payment: '',
  financial_calc_text: '',
  financial_calc_text1: '',
  book_first_name: 'Vārds',
  book_last_name: 'Uzvārds',
  book_phone: 'Tel. numurs',
  book_email: 'Epasts',
  book_enter_required_fields: '',
  book_marketing_title: '',
  book_marketing_checkbox1_text: '',
  book_marketing_checkbox1_details: '',
  book_marketing_checkbox2_text: '',
  book_marketing_checkbox2_details: '',
  book_marketing_checkbox3_text: '',
  book_marketing_checkbox3_details: '',
  book_policy: '',
  book_policy_details: '',
  book_submit: '',
  confirm_title: '',
  confirm_subtitle: '',
  availability_IN_STOCK: 'Pieejams tagad',
  availability_ON_THE_WAY: 'Nav salonā',
  view_more: 'APSKATĪT MODELI',
  book_a_test_ride: 'PIETEIKT TESTA BRAUCIENU',
  get_an_offer: 'SAŅEMT PIEDĀVĀJUMU',
  technical_data: 'Tehniskā informācija',
  technical_data_Power: 'Jauda',
  technical_data_Cylinder: 'Cylinder',
  'technical_data_Top speed': 'MAKS. ĀTRUMS',
  technical_data_Torque: 'GRIEZES MOMENTS',
  'technical_data_Drive Type': 'Drive Type',
  technical_data_Transmission: 'Transmission',
  'technical_data_Seat height': 'Seat height',
  'technical_data_Seat dimensions': 'Seat dimensions',
  'technical_data_Tank capacity': 'PILNA UZPILDE',
  'technical_data_Rear tire size': 'Rear tire size',
  'technical_data_Front wheel size': 'Front wheel size',
  'technical_data_Rear wheel size': 'Rear wheel size',
  vehicle_details_select_financing_option: 'Select financing option',
  vehicle_details_add_contact_info: 'Add contact info',
  vehicle_details_hide_financing_params: 'Hide parameters',
  vehicle_details_adjust_financing_params: 'Adjust parameters',
  book_field_required: 'Nepieciešams',
  request_test_ride: 'Request test ride',
  color_GRAY: 'Pēleks',
  color_WHITE: 'Balts',
  color_BLACK: 'Melns',
  color_RED: 'Sarkans',
  color_BLUE: 'Zils',
  color_GREEN: 'Zaļš',
  'promotion_Test ride your dream BMW': 'Test ride your dream BMW',
  'promotion_Build your ultimate BMW bike': 'Build your ultimate BMW bike',
  'promotion_Get the best deal': 'Get the best deal',
  'promotion_Trade in your old bike': 'Trade in your old bike',
  'promotion_Get the latest news': 'Get the latest news',
  'promotion_Lots of questions?': 'Lots of questions?',
  'promotion_action_Book a test ride': 'Book a test ride',
  promotion_action_Configure: 'Configure',
  'promotion_action_Request for offer': 'SAŅEMT PIEDĀVĀJUMU',
  'promotion_action_Trade-in offer': 'Trade-in offer',
  promotion_action_Subscribe: 'Subscribe',
  'promotion_action_Get contacted': 'Get contacted',
  total_price_disclaimer:
    'BMW Financial Services sadarbībā ar Citadele leasing piedāvā “kredītbrīvdienu akciju”, kura sniedz iespēju atlikt pamatsummas maksājumu līdz 6 mēnešiem (pirmos 6 mēnešus maksā tikai %):',
  total_price_disclaimer_bullet: 'Minimālā iemaksa 20%;',
  total_price_disclaimer_bullet_1: 'Maksimālais termiņš 60 mēn;',
  total_price_disclaimer_bullet_2: '6 mēnēnešu atliktā pamatsumma;',
  total_price_disclaimer_bullet_3: 'KASKO apdrošināšana obligāta;',
  total_price_disclaimer_bullet_4:
    'Pieejams klientiem ar stabiliem un labiem ienākumiem, ko individuāli izvērtē banka.',
  total_price_disclaimer_bullet_5:
    'Noformējot līzingu laika posmā no 08.04.2024 līdz 30.06.2024, tiek piemērota speciālā likme 2,99% +3 mēnešu Euribor. Piedāvājums spēkā sadarbībā ar Citadele leasing. Aizņemies atbildīgi, izvērtējot savas iespējas atmaksāt.',
  show_filters: 'Parādīt filtrus',
  offer_success_message:
    'Paldies, Jūsu pieteikums ir nosūtīts mūsu speciālistam, kurš ar Jums sazināsies jau tuvākajā laikā.',
  'package_Other equipment': 'Other equipment',
  no_results_found: 'Nav atrasti rezultāti',
  book_message: 'Ziņa',
  'Your filter selection did not return any matches.': 'Jūsu filtru meklējumiem nav rezultātu.',
  'Please modify your search criteria or': 'Lūdzu izmainīt meklēšanas kritērijus vai',
  'reset filters': 'notīriet filtru.',
  'to start again': '',
  map: 'Map',
  list: 'List',
  enter_location: 'Enter a location',
  card_price: 'Cena',
  card_special_price: 'Speciālā cena',
};
