export default {
  available_vehicles: 'Available vehicles',
  currency: 'EUR',
  currency_monthly: '',
  popup_close: '',
  navigate_back: 'Back',
  vehicle_list_no_matches: '',
  vehicle_list_other_vehicles: '',
  vehicle_list_sort_by: '',
  vehicle_list_sort_by_price: '',
  filter_models: 'Models',
  filter_location: '',
  filter_location_title: '',
  filter_location_search_radius: '',
  filter_location_search_distance: '',
  filter_reset: 'Reset filters',
  filter_price: 'Price',
  filter_color: 'Color',
  filter_availability: 'Availability',
  filter_power: 'Power',
  filter_torque: 'Torque',
  filter_applied_models: '',
  filter_applied_locations: '',
  filter_search_for_equipment: 'Search for equipment',
  vehicle_details_engine_output: '',
  vehicle_details_cylinder_capacity: '',
  vehicle_details_vehicle_identifier: '',
  vehicle_details_360_View: '',
  vehicle_details_overview: '',
  vehicle_details_gallery: 'Gallery',
  vehicle_details_video: '',
  vehicle_details_equipment: 'Equipment',
  vehicle_details_accessories: 'Equipment',
  vehicle_details_total_price: 'Cash price',
  vehicle_details_financing_from: 'Financing from',
  vehicle_details_open_calculator: 'Calculator',
  vehicle_details_request_offer: '',
  vehicle_details_real_images: 'Vehicle details',
  financial_calc_downpayment: 'Downpayment',
  financial_calc_period: 'Duration',
  financial_calc_remaining: 'Residual value',
  financial_calc_monthly_payment: '',
  financial_calc_text:
    'Disclaimer: This is the current price for this particular car. The price is determined by the dealer with the car in stock and is subject to change.',
  financial_calc_text1: '',
  book_first_name: 'Name',
  book_last_name: 'Surname',
  book_phone: 'Phone',
  book_email: 'Email',
  book_enter_required_fields: '',
  book_marketing_title: '',
  book_marketing_checkbox1_text: '',
  book_marketing_checkbox1_details: '',
  book_marketing_checkbox2_text: '',
  book_marketing_checkbox2_details: '',
  book_marketing_checkbox3_text: '',
  book_marketing_checkbox3_details: '',
  book_policy: '',
  book_policy_details: '',
  book_submit: '',
  confirm_title: '',
  confirm_subtitle: '',
  availability_IN_STOCK: 'In stock',
  availability_ON_THE_WAY: 'On the way',
  view_more: 'View more',
  book_a_test_ride: 'Book a test ride',
  get_an_offer: 'Get an offer',
  technical_data: 'Technical data',
  technical_data_Power: 'Power',
  technical_data_Cylinder: 'Cylinder',
  'technical_data_Top speed': 'Top speed',
  technical_data_Torque: 'Torque',
  'technical_data_Drive Type': 'Drive Type',
  technical_data_Transmission: 'Transmission',
  'technical_data_Seat height': 'Seat height',
  'technical_data_Seat dimensions': 'Seat dimensions',
  'technical_data_Tank capacity': 'Tank capacity',
  'technical_data_Rear tire size': 'Rear tire size',
  'technical_data_Front wheel size': 'Front wheel size',
  'technical_data_Rear wheel size': 'Rear wheel size',
  vehicle_details_select_financing_option: 'Select financing option',
  vehicle_details_add_contact_info: 'Add contact info',
  vehicle_details_hide_financing_params: 'Hide parameters',
  vehicle_details_adjust_financing_params: 'Adjust parameters',
  book_field_required: 'Required',
  request_test_ride: 'Request test ride',
  color_GRAY: 'Gray',
  color_WHITE: 'White',
  color_BLACK: 'Black',
  color_RED: 'Red',
  color_BLUE: 'Blue',
  'promotion_Test ride your dream BMW': 'Test ride your dream BMW',
  'promotion_Build your ultimate BMW bike': 'Build your ultimate BMW bike',
  'promotion_Get the best deal': 'Get the best deal',
  'promotion_Trade in your old bike': 'Trade in your old bike',
  'promotion_Get the latest news': 'Get the latest news',
  'promotion_Lots of questions?': 'Lots of questions?',
  'promotion_action_Book a test ride': 'Book a test ride',
  promotion_action_Configure: 'Configure',
  'promotion_action_Request for offer': 'Request for offer',
  'promotion_action_Trade-in offer': 'Trade-in offer',
  promotion_action_Subscribe: 'Subscribe',
  'promotion_action_Get contacted': 'Get contacted',
  total_price_disclaimer:
    'Disclaimer: This is the current price for this particular vehicle. The price is determined by the retailer with the vehicle in stock and is subject to change.',
  show_filters: 'Show filters',
  offer_success_message: 'Request for offer successfully sent. Our team will contact you as soon as possible.',
  'package_Other equipment': 'Other equipment',
  no_results_found: 'No results found',
  book_message: 'Message',
  enter_location: 'Enter a location',
  card_special_price: 'Campaign price',
  card_price: 'Price',
};
