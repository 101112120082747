import publicPath from './public-path';

import axios from 'axios';
axios.defaults.baseURL = publicPath;

import { createApp } from 'vue';
import App from './App.vue';
import Directives from './directives';
import router from './router';
import { createPinia } from 'pinia';
import qgmaps from '@/plugins/gmaps';
import Translator from './services/Translate/Translator';
import smoothscroll from 'smoothscroll-polyfill';

if (!document.getElementById('quadratura-motorrad')) {
  let main = document.getElementById('pageMain');
  if (main) {
    let container = document.createElement('div');
    container.id = 'quadratura-motorrad';
    main.insertBefore(container, document.getElementById('pageFooter'));
  }
}

smoothscroll.polyfill();

window.fallbackImage = require('@/assets/motorradFallback.svg');

const pinia = createPinia();
const app = createApp(App);
Directives(app);
app.use(router);
app.use(pinia);
app.use(qgmaps, {
  load: {
    apiKey: 'AIzaSyDiP-0e-uI9Xkup13OeE-Lo3dL_QzbCIZE',
    libraries: ['places'],
    mapId: '28da50e8563da00b',
    v: 'weekly',
  },
});
app.mixin({
  methods: {
    translate(value) {
      return translator.get(value);
    },
  },
});

const translator = new Translator();
app.config.globalProperties.$translator = translator;

axios.get('/config.json?v=1.0').then((response) => {
  let config = {};
  window.Locale = 'lv';
  if (response.data) {
    config = response.data;
    window.Locale = response.data.locale;
  }
  app.config.globalProperties.$config = config;
  translator.setLanguage(window.Locale);
  app.mount('#quadratura-motorrad');
});
