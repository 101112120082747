export default {
  available_vehicles: 'Tilgjengelige kjøretøy',
  currency: 'NOK',
  currency_monthly: 'Månad',
  popup_close: 'Stäng',
  navigate_back: 'Tilbake',
  vehicle_list_no_matches: 'Dessverre har vi ikke motorsykler som oppfyller spesifikasjonene dine.',
  vehicle_list_other_vehicles: 'Andre motorsykler på lager',
  vehicle_list_sort_by: 'Sorter etter',
  vehicle_list_sort_by_price: 'Pris',
  filter_models: 'Modeller',
  filter_location: 'Forhandler',
  filter_location_title: 'Søk forhandler',
  filter_location_search_radius: 'Søk i et spesifikt område eller velg foretrukket forhandler',
  filter_location_search_distance: 'Rekkevidden',
  filter_reset: 'Nullstill',
  filter_price: 'Pris',
  filter_color: 'Farge',
  filter_availability: 'Tilgjengelighet',
  filter_power: 'Kraft',
  filter_torque: 'Dreiemoment',
  filter_applied_models: 'Modeller',
  filter_search_for_equipment: 'Søk etter utstyr',
  filter_applied_locations: 'Plass',
  vehicle_details_engine_output: 'Effekt',
  vehicle_details_cylinder_capacity: 'Sylindervolum',
  vehicle_details_vehicle_identifier: 'ID',
  vehicle_details_360_View: '360° EKSTERIØR',
  vehicle_details_overview: 'OVERSIKT',
  vehicle_details_gallery: 'Bildegalleri',
  vehicle_details_video: 'Video',
  vehicle_details_equipment: 'Utstyr',
  vehicle_details_accessories: 'Tilbehør',
  vehicle_details_total_price: 'Kontantpris',
  vehicle_details_financing_from: '',
  vehicle_details_open_calculator: '',
  vehicle_details_request_offer: 'Kontakta mig',
  financial_calc_downpayment: '',
  financial_calc_period: '',
  financial_calc_remaining: '',
  financial_calc_monthly_payment: '',
  financial_calc_text: '',
  financial_calc_text1: '',
  book_first_name: 'Fornavn',
  book_last_name: 'Etternavn',
  book_phone: 'Telefonnummer',
  book_email: 'E-mail',
  book_enter_required_fields: 'Alle felter er obligatoriske',
  book_marketing_title: 'Samtykke til å motta markedsføring.',
  book_marketing_checkbox1_text:
    'Jeg ønsker å motta informasjon fra BMW via e-post, post, telefon/messenger-tjenester og in-car (hvis dette støttes).',
  book_marketing_checkbox1_details:
    'Jeg samtykker i at BMW Norge AS (org.nr. 971 130 776), Rolfsbuktveien 4 A, 1364 Fornebu, behandler og bruker de personopplysninger jeg har oppgitt (f.eks. kontaktopplysninger, personlige opplysninger som for eksempel fødselsdato og type førerkort, hobbyer og interesser hobbyer og interesser) i forbindelse med kundebehandling og valgt kommunikasjonsmåte av BMW produkt- og serviceinformasjon, samt markedsundersøkelser. BMW Norge AS kan i denne forbindelse også overføre disse opplysningene til visse nærstående selskaper (BMW Financial Services Scandinavia AB, Box 794, 191 27, Sollentuna), samt visse kontraktspartnere og verksteder (min foretrukne forhandler, partnere til mine bilkjøp, service- eller konsulentbesøk eller min regionale partner). Oppdateringer kan deles mellom de oppgitte selskapene.',
  book_marketing_checkbox2_text:
    'Videre ønsker jeg å motta spesialtilpassede BMW Group produkt- og tjenestetilbud basert på mine personlige preferanser og atferd, samt fra bruk av produkter og tjenester.',
  book_marketing_checkbox2_details:
    'Jeg samtykker i at personopplysninger brukes til å opprette en individuell kundeprofil ved hjelp av en statistisk prosedyre. Basert på denne profilen vil jeg motta markedsføringskommunikasjon med innhold som er relevant for meg, som f.eks. spesialtilpassede tilbud fra BMW Norge AS og visse nærstående selskaper (BMW Financial Services Scandinavia AB, Box 794, 191 27, Sollentuna), samt visse kontraktspartnere og verksteder (min foretrukne forhandler, partnere til mine bilkjøp, service- eller konsulentbesøk eller min regionale partner). Jeg samtykker dessuten i at kundeprofilen overføres fra BMW Norge AS til visse nærstående selskaper og mine kontraktspartnere og verksteder for ovennevnte formål. Hvis tilgjengelig kan følgende opplysninger jeg har oppgitt eller som er generert på bakgrunn av min bruk av produkter eller tjenester fra BMW Norge AS, visse nærstående selskap og deres kontraktspartnere og verksteder bli del av profilutviklingen: kontaktinformasjon (som f.eks. navn, adresse, e-postadresse); tilleggsinformasjon/preferanser (som f.eks. foretrukket forhandler, hobbyer); identifikasjonsopplysninger (som f.eks. kundenummer, kontraktsnummer); kundehistorikk (som f.eks. mottak av tilbud, kjøpsopplysninger for kjøretøy, forhandlerinformasjon); kjøretøysinformasjon (som f.eks. bruksdata for My BMW app: kjørelengde, cruising-rekkevidde); informasjon fra app-/nettside-/sosiale medier. En uttømmende liste med informasjon fra disse kategoriene finnes her.',
  book_policy: 'Endringsforespørsler vedr. samtykkeerklæringen.',
  book_policy_details:
    'Jeg kan når som helst trekke tilbake det samtykke jeg har avgitt til BMW Norge AS med fremtidig virkning ved å kontakte BMW kundeservice telefon 67 81 85 00 eller nordic.dataprivacy@bmw.no. Via de angitte kommunikasjonskanalene kan jeg også be om informasjon om mine personopplysninger som er lagret hos BMW Norge AS, samt korrigering, sletting eller blokkering av mine personopplysninger. Ytterligere detaljer om BMW Norge AS behandling av mine personopplysninger finnes online i BMWs personvernerklæring.',
  book_submit: 'Kontakta mig',
  confirm_title: 'Takk for din forespørsel',
  confirm_subtitle: 'Vi vil kontakte deg snart.',
  availability_IN_STOCK: 'På lager',
  availability_ON_THE_WAY: 'På vei',
  view_more: 'Vis mer',
  book_a_test_ride: 'Book a test ride',
  get_an_offer: 'Få et tilbud',
  technical_data: 'Tekniske data',
  technical_data_Power: 'Power',
  technical_data_Cylinder: 'Cylinder',
  'technical_data_Top speed': 'Top speed',
  technical_data_Torque: 'Torque',
  'technical_data_Drive Type': 'Drive Type',
  technical_data_Transmission: 'Transmission',
  'technical_data_Seat height': 'Seat height',
  'technical_data_Seat dimensions': 'Seat dimensions',
  'technical_data_Tank capacity': 'Tank capacity',
  'technical_data_Rear tire size': 'Rear tire size',
  'technical_data_Front wheel size': 'Front wheel size',
  'technical_data_Rear wheel size': 'Rear wheel size',
  vehicle_details_select_financing_option: 'Select financing option',
  vehicle_details_add_contact_info: 'Add contact info',
  vehicle_details_hide_financing_params: 'Hide parameters',
  vehicle_details_adjust_financing_params: 'Adjust parameters',
  book_field_required: 'Required',
  request_test_ride: 'Request test ride',
  color_GRAY: 'Grå',
  color_WHITE: 'Hvit',
  color_BLACK: 'Svart',
  color_RED: 'Rød',
  color_BLUE: 'Blå',
  'promotion_Test ride your dream BMW': 'Test ride your dream BMW',
  'promotion_Build your ultimate BMW bike': 'Build your ultimate BMW bike',
  'promotion_Get the best deal': 'Get the best deal',
  'promotion_Trade in your old bike': 'Trade in your old bike',
  'promotion_Get the latest news': 'Get the latest news',
  'promotion_Lots of questions?': 'Lots of questions?',
  'promotion_action_Book a test ride': 'Bestill en prøvetur',
  promotion_action_Configure: 'Configure',
  'promotion_action_Request for offer': 'Request for offer',
  'promotion_action_Trade-in offer': 'Trade-in offer',
  promotion_action_Subscribe: 'Subscribe',
  'promotion_action_Get contacted': 'Get contacted',
  total_price_disclaimer:
    'Ansvarsfraskrivelse: Dette er den gjeldende prisen for dette spesifikke kjøretøyet. Prisen bestemmes av forhandleren som har kjøretøyet på lager og kan endres.',
  show_filters: 'Vis filtre',
  offer_success_message: 'Forespørsel om tilbud er sendt. Vårt team vil kontakte deg så snart som mulig.',
  'package_Other equipment': 'Annet utstyr',
  no_results_found: 'Ingen resultater funnet',
  book_message: 'Melding',
  map: 'Kart',
  list: 'Liste',
  search_within_specific_area: 'Søk innenfor et bestemt område eller velg en forhandler nedenfor',
  filter_distance: 'Avstand',
  enter_location: 'Skriv inn et sted',
  'Your filter selection did not return any matches.': 'Ingen treff med dine valg av filtre.',
  'Please modify your search criteria or': 'Prøv å endre søkekriteriene dine eller',
  'reset filters': 'tilbakestill filtrene',
  'to start again': ' for å begynne på nytt.',
  location: 'Sted',
  card_special_price: 'Kampanjepris',
  card_price: 'Kontantpris',
};
