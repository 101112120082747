<template>
  <a class="back-to-touchtable" :href="redirectLink" v-if="isOnTouchtable">
    <img src="~@/assets/icons/back.svg" alt="" />
  </a>
  <router-view />
</template>

<style lang="scss">
@import '@/scss/main.scss';

#quadratura-motorrad {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  // min-height: 100vh;
  margin-top: 85px;
}
.back-to-touchtable {
  position: absolute;
  left: 20px;
  cursor: pointer;
  z-index: 100;
}
</style>
<script setup>
import { ref } from 'vue';

function getParameterByName(name, url = window.location.href) {
  name = name.replace(/[[\]]/g, '\\$&');
  let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return '';
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}
const isOnTouchtable = ref(getParameterByName('touchtable').length > 0);
const redirectLink = ref(getParameterByName('referer'));
</script>
