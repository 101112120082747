export default {
  available_vehicles: 'galimi modeliai',
  currency: 'EUR',
  currency_monthly: '',
  popup_close: '',
  navigate_back: 'Atgal',
  vehicle_list_no_matches: '',
  vehicle_list_other_vehicles: '',
  vehicle_list_sort_by: '',
  vehicle_list_sort_by_price: '',
  filter_models: 'Modelis',
  filter_location: '',
  filter_location_title: '',
  filter_location_search_radius: '',
  filter_location_search_distance: '',
  filter_reset: 'Reset filters',
  filter_price: 'Kaina',
  filter_color: 'Spalva',
  filter_availability: 'Prieinamumas',
  filter_power: 'Galia',
  filter_torque: 'Sukimo momentas',
  filter_applied_models: '',
  filter_applied_locations: '',
  filter_search_for_equipment: 'Ieškoti įrangos',
  vehicle_details_engine_output: '',
  vehicle_details_cylinder_capacity: '',
  vehicle_details_vehicle_identifier: '',
  vehicle_details_360_View: '',
  vehicle_details_overview: '',
  vehicle_details_gallery: 'Galerija',
  vehicle_details_video: '',
  vehicle_details_equipment: 'Įranga',
  vehicle_details_accessories: 'Įranga',
  vehicle_details_total_price: 'Kaina',
  vehicle_details_financing_from: 'Financing from',
  vehicle_details_open_calculator: 'Calculator',
  vehicle_details_request_offer: '',
  vehicle_details_real_images: 'Vehicle details',
  financial_calc_downpayment: 'Downpayment',
  financial_calc_period: 'Duration',
  financial_calc_remaining: 'Residual value',
  financial_calc_monthly_payment: '',
  financial_calc_text:
    'Perspėjimas: tai dabartinė kaina šii konkrečiai transporto priemonei. Kainą nustato pardavėjas ir ji gali keistis.',
  financial_calc_text1: '',
  book_first_name: 'Vardas',
  book_last_name: 'Pavardė',
  book_phone: 'Tel. numeris',
  book_email: 'El. pašto adresas',
  book_enter_required_fields: '',
  book_marketing_title: '',
  book_marketing_checkbox1_text: '',
  book_marketing_checkbox1_details: '',
  book_marketing_checkbox2_text: '',
  book_marketing_checkbox2_details: '',
  book_marketing_checkbox3_text: '',
  book_marketing_checkbox3_details: '',
  book_policy: '',
  book_policy_details: '',
  book_submit: '',
  confirm_title: '',
  confirm_subtitle: '',
  availability_IN_STOCK: 'Turime sandelyje',
  availability_ON_THE_WAY: 'Pakeliui',
  view_more: 'Žiūrėti daugiau',
  book_a_test_ride: 'Book a test ride',
  get_an_offer: 'Gauti pasiūlymą',
  technical_data: 'Techniniai duomenys',
  technical_data_Power: 'Galia',
  technical_data_Cylinder: 'Cylinder',
  'technical_data_Top speed': 'Maks. greitis',
  technical_data_Torque: 'Sukimo momentas',
  'technical_data_Drive Type': 'Drive Type',
  technical_data_Transmission: 'Transmission',
  'technical_data_Seat height': 'Seat height',
  'technical_data_Seat dimensions': 'Seat dimensions',
  'technical_data_Tank capacity': 'Bako talpa',
  'technical_data_Rear tire size': 'Rear tire size',
  'technical_data_Front wheel size': 'Front wheel size',
  'technical_data_Rear wheel size': 'Rear wheel size',
  vehicle_details_select_financing_option: 'Select financing option',
  vehicle_details_add_contact_info: 'Add contact info',
  vehicle_details_hide_financing_params: 'Hide parameters',
  vehicle_details_adjust_financing_params: 'Adjust parameters',
  book_field_required: 'Privalomas',
  request_test_ride: 'Request test ride',
  color_GRAY: 'Pilka',
  color_WHITE: 'Balta',
  color_BLACK: 'Juoda',
  color_RED: 'Raudona',
  color_BLUE: 'Mėlyna',
  'promotion_Test ride your dream BMW': 'Test ride your dream BMW',
  'promotion_Build your ultimate BMW bike': 'Build your ultimate BMW bike',
  'promotion_Get the best deal': 'Get the best deal',
  'promotion_Trade in your old bike': 'Trade in your old bike',
  'promotion_Get the latest news': 'Get the latest news',
  'promotion_Lots of questions?': 'Lots of questions?',
  'promotion_action_Book a test ride': 'Registruotis bandomajam važiavimui',
  promotion_action_Configure: 'Configure',
  'promotion_action_Request for offer': 'Gauti pasiūlymą',
  'promotion_action_Trade-in offer': 'Trade-in offer',
  promotion_action_Subscribe: 'Subscribe',
  'promotion_action_Get contacted': 'Get contacted',
  total_price_disclaimer:
    'Perspėjimas: tai dabartinė kaina šii konkrečiai transporto priemonei. Kainą nustato pardavėjas ir ji gali keistis.',
  show_filters: 'Rodyti filtrus',
  offer_success_message: 'Request for offer successfully sent. Our team will contact you as soon as possible.',
  'package_Other equipment': 'Kita įranga',
  no_results_found: 'Rezultatas nerastas',
  book_message: 'Pranešimas',
  map: 'Map',
  list: 'List',
  card_price: 'Kaina',
  card_special_price: 'Speciali Kaina',
};
