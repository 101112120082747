export default {
  available_vehicles: 'saadaval sõidukit',
  currency: 'EUR',
  currency_monthly: '',
  popup_close: '',
  navigate_back: 'Tagasi',
  vehicle_list_no_matches: '',
  vehicle_list_other_vehicles: '',
  vehicle_list_sort_by: '',
  vehicle_list_sort_by_price: '',
  filter_models: 'Mudelid',
  filter_location: '',
  filter_location_title: '',
  filter_location_search_radius: '',
  filter_location_search_distance: '',
  filter_reset: 'Nulli filtrid',
  filter_price: 'Hind',
  filter_color: 'Värv',
  filter_availability: 'Saadavus',
  filter_power: 'Võimsus',
  filter_torque: 'Pöördemoment',
  filter_applied_models: '',
  filter_applied_locations: '',
  filter_search_for_equipment: 'Otsi varustust',
  vehicle_details_engine_output: '',
  vehicle_details_cylinder_capacity: '',
  vehicle_details_vehicle_identifier: '',
  vehicle_details_360_View: '',
  vehicle_details_overview: '',
  vehicle_details_gallery: 'Galerii',
  vehicle_details_video: '',
  vehicle_details_equipment: 'Varustus',
  vehicle_details_accessories: 'Varustus',
  vehicle_details_total_price: 'Hind',
  vehicle_details_financing_from: 'Financing from',
  vehicle_details_open_calculator: 'Calculator',
  vehicle_details_request_offer: '',
  vehicle_details_real_images: 'Sõiduki üksikasjad',
  financial_calc_downpayment: 'Downpayment',
  financial_calc_period: 'Duration',
  financial_calc_remaining: 'Residual value',
  financial_calc_monthly_payment: '',
  financial_calc_text:
    'Lisainformatsioon: See on selle konkreetse sõiduki praegune hind. Hinna määrab laos olevale sõidukile jaemüüja ja see võib muutuda.',
  financial_calc_text1: '',
  book_first_name: 'Eesnimi',
  book_last_name: 'Perekonnanimi',
  book_phone: 'Telefon',
  book_email: 'E-mail',
  book_enter_required_fields: '',
  book_marketing_checkbox1_text: '',
  book_marketing_checkbox1_details: '',
  book_marketing_checkbox2_text: '',
  book_marketing_checkbox2_details: '',
  book_marketing_checkbox3_text: '',
  book_marketing_checkbox3_details: '',
  book_policy: '',
  book_policy_details: '',
  book_submit: '',
  confirm_title: '',
  confirm_subtitle: '',
  availability_IN_STOCK: 'Laos',
  availability_ON_THE_WAY: 'Peagi saabumas',
  view_more: 'Vaata rohkem',
  book_a_test_ride: 'Book a test ride',
  get_an_offer: 'Soovin pakkumist',
  technical_data: 'Tehnilised andmed',
  technical_data_Power: 'Power',
  technical_data_Cylinder: 'Cylinder',
  'technical_data_Top speed': 'Max kiirus',
  technical_data_Torque: 'Max pöördemoment',
  'technical_data_Drive Type': 'Drive Type',
  technical_data_Transmission: 'Transmission',
  'technical_data_Seat height': 'Seat height',
  'technical_data_Seat dimensions': 'Seat dimensions',
  'technical_data_Tank capacity': 'Paagi maht',
  'technical_data_Rear tire size': 'Rear tire size',
  'technical_data_Front wheel size': 'Front wheel size',
  'technical_data_Rear wheel size': 'Rear wheel size',
  vehicle_details_select_financing_option: 'Select financing option',
  vehicle_details_add_contact_info: 'Add contact info',
  vehicle_details_hide_financing_params: 'Hide parameters',
  vehicle_details_adjust_financing_params: 'Adjust parameters',
  book_field_required: 'Required',
  request_test_ride: 'Request test ride',
  color_GRAY: 'Hall',
  color_WHITE: 'Valge',
  color_BLACK: 'Must',
  color_RED: 'Red',
  color_BLUE: 'Sinine',
  'promotion_Test ride your dream BMW': 'Proovisõit sinu unistuste BMW mootorrattaga',
  'promotion_Build your ultimate BMW bike': 'Sinu isikupärane BMW mootorratas',
  'promotion_Get the best deal': 'Get the best deal',
  'promotion_Trade in your old bike': 'Trade in your old bike',
  'promotion_Get the latest news': 'Get the latest news',
  'promotion_Lots of questions?': 'Lots of questions?',
  'promotion_action_Book a test ride': 'BRONEERI PROOVISÕIT',
  promotion_action_Configure: 'KONFIGUREERI',
  'promotion_action_Request for offer': 'Request for offer',
  'promotion_action_Trade-in offer': 'Trade-in offer',
  promotion_action_Subscribe: 'Subscribe',
  'promotion_action_Get contacted': 'Get contacted',
  total_price_disclaimer:
    'Lisainformatsioon: See on konkreetse sõiduki hetkehind. Hinna määrab laos olevale sõidukile jaemüüja ja see võib muutuda.',
  show_filters: 'Kuva kõik filtrid',
  offer_success_message: 'Teie taotlus on edukalt saadetud. Meie meeskond võtab teiega peagi ühendust',
  'package_Other equipment': 'Other equipment',
  no_results_found: 'Tulemusi ei leitud',
  book_marketing_title: 'Annan nõusoleku enda isikuandmete salvestamiseks turunduslikel eesmärkidel',
  marketing_no_commitment: 'Sa ei ole võtnud veel mingisuguseid kohustusi, sinu valikute põhjal teeme sulle pakkumise',
  book_message: 'Lisainformatsioon',
  'Your filter selection did not return any matches.': 'Otsingu tulemustele ei leitud ühtegi vastet.',
  'Please modify your search criteria or': 'Palun muutke otsingutingimusi.',
  map: 'Map',
  list: 'List',
  enter_location: 'Enter a location',
  card_price: 'Hind',
  card_special_price: 'Erihind',
};
