import { createRouter, createWebHashHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "HomeView",
    component: () => import(/* webpackChunkName: "motorrad" */ "../views/HomeView.vue"),
  },
  {
    path: "/bike/:id",
    name: "BikeDetails",
    component: () => import(/* webpackChunkName: "motorrad" */ "../views/BikeDetails.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  },
});

export default router;
