<template>
  <div class="qgmap" ref="mapRef" @click="handleMarkerClose"></div>
</template>
<script setup>
import { ref, onMounted, defineProps, watch } from 'vue';

const props = defineProps({
  load: {
    type: Object,
    default() {
      return {};
    },
  },
  options: {
    type: Object,
    default() {
      return {};
    },
  },
  markers: {
    type: Array,
    default() {
      return [];
    },
  },
});

const mapRef = ref(null);
const map = ref(null);
const installedMarkers = ref([]);
const infoWindow = ref(null);

watch(
  () => props.markers,
  () => {
    setupMarkers();
  }
);
onMounted(() => {
  window.googleMapsLoader.load().then(setup);
});

function setup() {
  map.value = new window.google.maps.Map(mapRef.value, {
    center: { lat: -34.397, lng: 150.644 },
    zoom: 4,
    ...props.options,
  });
  setupInfoWindow();
  setupMarkers();
}
function setupMarkers() {
  if (!map.value || !props.markers) {
    return;
  }
  props.markers.forEach((marker) => {
    let m = getInstalledMarker(marker);
    if (!m) {
      m = new window.google.maps.Marker({
        ...marker.options,
        map: map.value,
      });
      m.addListener('click', () => {
        if (infoWindow.value) {
          infoWindow.value.close();
          infoWindow.value.setContent(m.getTitle());
          infoWindow.value.open(m.getMap(), m);

          if (marker.click) {
            marker.click();
          }
        }
      });
      m.addListener('mouseover', () => {
        if (infoWindow.value) {
          infoWindow.value.close();
          infoWindow.value.setContent(m.getTitle());
          infoWindow.value.open(m.getMap(), m);
        }
      });

      m.addListener('mouseout', () => {
        if (infoWindow.value) {
          infoWindow.value.close();
        }
      });
      installedMarkers.value.push(m);
    }
    if (marker.options && marker.options.icon) {
      m.setIcon(marker.options.icon);
    }
  });

  installedMarkers.value.forEach((m) => {
    if (!isOnMarkerList(m)) {
      m.setMap(null);
    } else {
      m.setMap(map.value);
    }
  });
}
function getInstalledMarker(marker) {
  for (let i = 0; i < installedMarkers.value.length; i++) {
    let m = installedMarkers.value[i];
    if (m.getPosition().lat() == marker.options.position.lat && m.getPosition().lng() == marker.options.position.lng) {
      return m;
    }
  }
  return null;
}
function isOnMarkerList(m) {
  for (let i = 0; i < props.markers.length; i++) {
    let marker = props.markers[i];
    if (m.getPosition().lat() == marker.options.position.lat && m.getPosition().lng() == marker.options.position.lng) {
      return true;
    }
  }
  return false;
}
function setupInfoWindow() {
  infoWindow.value = new window.google.maps.InfoWindow();
}

function handleMarkerClose(e) {
  if (e.__gm_internal__noClick !== true) {
    if (infoWindow.value) {
      infoWindow.value.close();
    }
  }
  console.log('close', e, map.value);
}
</script>

<style scoped>
.qgmap {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
</style>
