<template>
  <input ref="autocomplete" :placeholder="translate('enter_location')" class="input-component body-text" />
</template>
<script setup>
import { ref, defineProps, onMounted, defineEmits } from 'vue';
const props = defineProps({
  options: {
    type: Object,
    default() {
      return {};
    },
  },
});
const autocomplete = ref(null);

const emit = defineEmits(['placeChange']);
onMounted(() => {
  window.googleMapsLoader.load().then(setup);
});

function setup() {
  let autocompleteVar;
  autocompleteVar = new window.google.maps.places.Autocomplete(autocomplete.value, props.options);

  if (props.options.fields) {
    autocompleteVar.setFields(props.options.fields);
  }
  autocompleteVar.addListener('place_changed', () => {
    let place = autocompleteVar.getPlace();
    console.log(place, 'palce');
    if (!place.geometry || !place.geometry.location) {
      emit('placeChange', null);
      return;
    }
    emit('placeChange', place);
  });
}
</script>
