
import QGMap from '@/components/QGMap'
import QGMapAutocomplete from '@/components/QGMapAutocomplete'
import { Loader } from "@googlemaps/js-api-loader";

export default {
    install: (app, options) => {
        window.googleMapsLoader = new Loader({
            ...options.load,
        });
        app.component('QGMap', QGMap);
        app.component('QGMapAutocomplete', QGMapAutocomplete);
    }
}