import { nextTick } from "vue";

export default function (Vue) {
  let HANDLER = "_vue_clickaway_handler";

  function bind(el, binding) {
    unbind(el, binding);

    const callback = binding.value.callback ? binding.value.callback : binding.value;

    if (typeof callback !== "function") {
      if (process.env.NODE_ENV !== "production") {
        Vue.util.warn(
          "v-" + binding.name + '="' + binding.expression + '" expects a function value, ' + "got " + callback
        );
      }
      return;
    }
    let initialMacrotaskEnded = false;
    let isDraggingWithinExclude = false;

    setTimeout(function () {
      initialMacrotaskEnded = true;
    }, 0);
    el[HANDLER] = function (ev) {
      if (
        initialMacrotaskEnded &&
        ev.target instanceof Element &&
        !el.contains(ev.target) &&
        (!document.querySelector(binding.value.exclude) ||
          !document.querySelector(binding.value.exclude).contains(ev.target)) &&
        !isDraggingWithinExclude
      ) {
        return callback(ev);
      }
    };

    document.documentElement.addEventListener("click", el[HANDLER], false);

    nextTick(() => {
      if (binding.value.exclude) {
        const excludeElement = document.querySelector(binding.value.exclude);
        if (excludeElement) {
          const handleMouseDown = (e) => {
            if (excludeElement.contains(e.target)) {
              isDraggingWithinExclude = true;
            }
          };

          const handleMouseUp = () => {
            if (isDraggingWithinExclude) {
              setTimeout(function () {
                isDraggingWithinExclude = false;
              }, 0);
            }
          };

          excludeElement.addEventListener("mousedown", handleMouseDown);

          document.documentElement.addEventListener("mouseup", handleMouseUp);
        }
      }
    });
  }

  function unbind(el, binding) {
    document.documentElement.removeEventListener("click", el[HANDLER], false);
    if (binding.value.exclude) {
      const excludeElement = document.querySelector(binding.value.exclude);
      if (excludeElement) {
        excludeElement.removeEventListener("mousedown", null);
        document.documentElement.removeEventListener("mouseup", null);
      }
    }
    delete el[HANDLER];
  }

  Vue.directive("click-outside", {
    beforeMount: bind,
    updated: function (el, binding) {
      if (binding.value === binding.oldValue) return;
      bind(el, binding);
    },
    beforeUnmount: unbind,
  });
}
