import Swedish from './se';
import English from './en';
import Norwegian from './no';
import Danish from './dk';
import Finnish from './fi';
import Latvian from './lv';
import Lithuanian from './lt';
import Estonian from './ee';

class Translator {
  constructor() {
    this.translations = {};
    this.untranslated = {};
  }

  setLanguage(locale) {
    switch (locale) {
      case 'se':
        this.translations = Swedish;
        break;
      case 'no':
        this.translations = Norwegian;
        break;
      case 'fi':
        this.translations = Finnish;
        break;
      case 'dk':
        this.translations = Danish;
        break;
      case 'lv':
        this.translations = Latvian;
        break;
      case 'lt':
        this.translations = Lithuanian;
        break;
      case 'ee':
        this.translations = Estonian;
        break;
      default:
        this.translations = English;
    }
  }

  get(word) {
    if (this.translations[word]) {
      return this.translations[word];
    } else {
      this.untranslated[word] = word;
    }
    return word;
  }
}

export default Translator;
